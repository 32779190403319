import React, { useState } from 'react';
import Profile from './components/Profile';
import { WaveDividerTop } from './components/WaveDivider';
import './components/styles/profile.css';
import Navbar from './components/Navbar';

export default function Home({ active, setActive }) {
  const [renderSideNav, setRenderSideNav] = useState(false);

  return (
    <div className='bg-background-300 flex flex-col justify-between min-h-[670px]'>
      <Navbar
        active={active}
        setActive={setActive}
        renderSideNav={renderSideNav}
      />
      <Profile setActive={setActive} setRenderSideNav={setRenderSideNav} />
      <WaveDividerTop />
    </div>
  );
}
