import React from 'react';
import { BiDownload, BiMailSend, BiNews } from 'react-icons/bi';
import { FaHouseChimney, FaUser, FaCode } from 'react-icons/fa6';
import ScrollIntoView from 'react-scroll-into-view';

import './styles/navbar.css';

export default function Navbar({ active, setActive, renderSideNav }) {
  const navItems = [
    { id: 1, name: 'Home', icon: <FaHouseChimney className='h-10 w-6' /> },
    { id: 2, name: 'Latest', icon: <BiNews className='h-10 w-6' /> },
    { id: 3, name: 'About', icon: <FaUser className='h-10 w-6' /> },
    { id: 4, name: 'Experience', icon: <FaCode className='h-10 w-6' /> },
    { id: 5, name: 'Contact', icon: <BiMailSend className='h-10 w-6' /> },
  ];

  const getActiveClass = (name) => {
    let classes = 'header-option text-white';
    if (active === name) {
      classes = 'header-option text-action mx-4 scale-[1.25]';
    }

    return classes;
  };

  return (
    <>
      <nav
        id='Home'
        className='container mx-auto flex flex-wrap items-center justify-between px-4 pt-2'
      >
        <div className='flex cta-btn resume-btn h-8 rounded-md bg-action text-white active:scale-[.98] justify-center hover:scale-[1.02] flex-no-shrink items-center mr-6 py-3 text-grey-darkest'>
          <a href='MalikOmarResume.pdf' download='MalikOmarResume.pdf'>
            <BiDownload className='inline mr-1 h-[20px] w-[20px]' />
            Resume
          </a>
        </div>

        <input className='menu-btn hidden' type='checkbox' id='menu-btn' />
        <label
          className='menu-icon block cursor-pointer md:hidden px-2 py-4 relative select-none'
          htmlFor='menu-btn'
        >
          <span className='navicon bg-grey-darkest flex items-center relative'></span>
        </label>

        <ul className='menu border-b md:border-none flex gap-x-3 justify-end list-reset m-0 w-full md:w-auto'>
          {navItems.map((item, i) => (
            <ScrollIntoView
              key={item.id}
              selector={`#${item.name}`}
              alignToTop={true}
            >
              <li className='border-t md:border-none'>
                <button
                  className={getActiveClass(item.name)}
                  onClick={() => setActive(item.name)}
                >
                  {item.name}
                </button>
              </li>
            </ScrollIntoView>
          ))}
        </ul>
      </nav>
      {renderSideNav ? (
        <SideNav active={active} setActive={setActive} navItems={navItems} />
      ) : null}
    </>
  );
}

const SideNav = ({ active, setActive, navItems }) => {
  const getActiveSidebarClass = (name) => {
    let classes = 'text-white mx-3';
    if (active === name) {
      classes = 'text-action mx-3 scale-[1.1]';
    }

    return classes;
  };

  const dividerClass = (name) => {
    if (name === 'Latest') {
      return 'divider-left border-y border-y-white w-full';
    }

    if (name === 'Experience') {
      return 'divider-right divider-left border-y border-y-white border-b border-b-white w-full';
    }
  };

  return (
    <div className='sidenav animate__animated animate__fadeIn bg-secondary rounded-r-md fixed left-0 flex flex-col items-center justify-center -translate-y-1/2 top-1/2 z-50'>
      {navItems?.map((item, i) => (
        <span
          key={i}
          className={`sidenav-item h-[74px] flex items-center ${dividerClass(
            item.name
          )}`}
        >
          <ScrollIntoView selector={`#${item.name}`} alignToTop={true}>
            <button
              key={item.id}
              className={`${getActiveSidebarClass(item.name)} p-1`}
              onClick={() => setActive(item.name)}
            >
              {item.icon}
            </button>
          </ScrollIntoView>
        </span>
      ))}
    </div>
  );
};
