import React, { useEffect, useRef } from 'react';
import SubHeading from '../reusable-utilities/SubHeading';
import { CTAButtons } from '../home/components/Profile';
import useReturnIntersecting from '../reusable-utilities/hooks/Hooks';

import './styles/aboutMe.css';

export default function AboutMe({ setActive }) {
  const elemRef = useRef(null);
  const isInView = useReturnIntersecting(elemRef);

  useEffect(() => {
    if (isInView) {
      setActive('About');
    }
  }, [isInView, setActive]);

  const aboutData = {
    desc: "I'm a passionate Fullstack Web Developer on a quest to bring digital ideas to life. With a strong foundation in both frontend and backend development, I'm on a mission to craft seamless, user-friendly experiences. My journey began with countless late-night coding sessions, where I developed a knack for turning complex problems into elegant solutions.",
  };

  return (
    <div id='About' className='flex flex-col justify-center items-center mt-12'>
      <div className='about-me-parent max-w-[1000px] w-[70%]'>
        <SubHeading title='About Me' subTitle='Why Choose Me' />

        <AboutCard innerRef={elemRef} data={aboutData} isInView={isInView} />
      </div>
    </div>
  );
}

const AboutCard = ({ data, innerRef, isInView }) => {
  return (
    <div
      id='AboutMeCard'
      ref={innerRef}
      className={`${
        isInView
          ? 'animate__animated animate__fadeIn'
          : 'animate__animated animate__fadeOut'
      } about-me-card flex w-full rounded-lg`}
    >
      <div className='about-me-picture rounded-l-lg w-[50%]' />
      <div className='about-me-details w-[50%] p-8'>
        <span className='text-base'>{data.desc}</span>
        {/* <div className='mt-20 max-h-[370px] overflow-y-auto'>
          <div className='highlights-heading text-base mb-2 text-background-300'>
            <span>{data.heading}</span>
          </div>

          {data.story}
        </div> */}
        <div className='about-me-btns mt-3'>
          <CTAButtons />
        </div>
      </div>
    </div>
  );
};
