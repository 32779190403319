import React, { useEffect, useRef } from 'react';
import SubHeading from '../reusable-utilities/SubHeading';
import useReturnIntersecting from '../reusable-utilities/hooks/Hooks';
import { FaLink, FaDiceD20 } from 'react-icons/fa6';
import DgnKeeperImgs from './DgnKeeperImgs';

import './styles/latestUpdate.css';

const renderDesc = () => {
  const techStack = [
    { id: 1, name: 'Python', color: 'bg-[#ffde57] text-[#323330]' },
    { id: 2, name: 'FastAPI', color: 'bg-[#05998b]' },
    { id: 3, name: 'PostgreSQL', color: 'bg-[#0064a5] text-white' },
    {
      id: 4,
      name: 'JavaScript',
      color: 'bg-[#F0DB4F] text-[#323330]',
    },
    { id: 5, name: 'React JS', color: 'bg-[#61DBFB]' },
    { id: 6, name: 'TailwindCSS', color: 'bg-cyan-300' },
    {
      id: 7,
      name: 'Redux Toolkit',
      color: 'bg-[#764abc] text-white',
    },
    { id: 8, name: 'DigitalOcean', color: 'bg-[#122c74] text-white' },
    { id: 9, name: 'Docker', color: 'bg-[#0db7ed]' },
    { id: 10, name: 'Authentication', color: 'bg-slate-300' },
  ];

  return techStack.map((tech) => (
    <span key={tech.id} className={`${tech.color} px-3 py-1 rounded-full`}>
      {tech.name}
    </span>
  ));
};

const projDesc = {
  mainDesc:
    'This is an RPG management tool aimed at Game Masters(GMs) and players alike. Users can host a game & their friends can join with a passcode created by the host.',
  genFeatureHeader: 'Here are Some Features',
  genFeature1: 'Session tracking & access to campaign information.',
  genFeature2:
    'Authentication & Authorization to ensure correct access of game data.',
  genFeature3: 'Character management, ability/spell tracking, and journaling.',
  genFeature4:
    'Leverage the freedom of Markdown to fully tailor your descriptions and presentation exactly how you vision it. If you are unfamiliar with Markdown, we provide various templates to start with.',
  gmFeature1:
    'GMs can organize their campaign data, including pictures that are stored in our Spaces Bucket.',
  gmFeature2:
    'GMs can plan ahead by choosing which game assets are available for players to view.',
};

export default function LatestUpdate({ setActive }) {
  const elemRef = useRef(null);
  const isInView = useReturnIntersecting(elemRef);

  useEffect(() => {
    if (isInView) {
      setActive('Latest');
    }
  }, [isInView, setActive]);

  return (
    <div
      id='Latest'
      className='flex flex-col justify-center items-center mt-12'
    >
      <div className='latest-parent max-w-[1000px] w-[70%]'>
        <SubHeading title='Latest Update' subTitle='Working on a demo video' />

        <ProjectCard innerRef={elemRef} isInView={isInView} />
      </div>
    </div>
  );
}

const ProjectCard = ({ innerRef, isInView }) => {
  const featureListMaker = (info) => {
    return (
      <span className='text-base '>
        <FaDiceD20 className='mr-1 inline text-secondary' />
        {info}
      </span>
    );
  };

  return (
    <div
      id='ProjectCard'
      ref={innerRef}
      className={`${
        isInView
          ? 'animate__animated animate__fadeIn'
          : 'animate__animated animate__fadeOut'
      } project-card relative overflow-hidden flex w-full rounded-lg max-h-[600px]`}
    >
      <div
        id='LeftSide'
        className='project-card-left flex flex-col w-[50%] pt-6 px-6 pb-2'
      >
        <div className='project-details max-h-[500px] overflow-y-auto flex flex-col gap-y-1 '>
          <a
            href='https://dgnkeeper.com'
            target='_blank'
            rel='noreferrer noopener'
            className='text-lg font-semibold underline text-background-300 mx-auto'
          >
            DGNKEEPER
          </a>
          <span className='text-base'>{projDesc.mainDesc}</span>
          <span className='text-lg font-semibold underline text-background-300 mx-auto'>
            {projDesc.genFeatureHeader}
          </span>
          {featureListMaker(projDesc.gmFeature1)}
          {featureListMaker(projDesc.gmFeature2)}
          {featureListMaker(projDesc.genFeature2)}
          {featureListMaker(projDesc.genFeature1)}
          {featureListMaker(projDesc.genFeature3)}
          {featureListMaker(projDesc.genFeature4)}

          <span className='text-lg font-semibold text-background-300 my-2 mx-auto'>
            And much more!
          </span>
        </div>

        <div className='h-[1.5px] my-4 bg-background-300' />

        <div className=''>
          <div className='flex flex-wrap gap-x-2 gap-y-1'>{renderDesc()}</div>
        </div>

        <div className='flex justify-center items-center mt-3'>
          <a
            href='https://dgnkeeper.com/'
            target='_blank'
            rel='noreferrer noopener'
            className='hover:underline hover:underline-offset-[2px] text-action'
          >
            <FaLink className='mr-1 inline' />
            Take a look here!
          </a>
        </div>
      </div>

      <div className='project-picture rounded-r-lg w-[50%]'>
        <DgnKeeperImgs />
      </div>
    </div>
  );
};

<div className='absolute top-[25%] left-1/2 bg-orange-300 rounded-md px-2 py-1 -translate-y-1/2 -translate-x-1/2'>
  Demo video coming soon
</div>;
