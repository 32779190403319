import React from 'react';

import './styles/subHeading.css';

export default function SubHeading({ title, subTitle }) {
  return (
    <div className='flex flex-col items-center w-full mb-12'>
      <div className='sub-heading text-background-300 tracking-[3px] mt-2 mb-2 text-2xl'>
        <span>{title}</span>
      </div>

      {subTitle ? (
        <div className='mb-2 text-lg'>
          <span>{subTitle}</span>
        </div>
      ) : (
        <div></div>
      )}

      <Seperator />
    </div>
  );
}

const Seperator = () => {
  return (
    <div className='flex items-center relative w-[280px] mt-2'>
      <div className='w-full h-[2px] bg-background'>
        <div className='h-[10px] w-full absolute -translate-y-[40%] flex justify-center'>
          <div className='w-[35px] rounded-xl bg-action'></div>
        </div>
      </div>
    </div>
  );
};
