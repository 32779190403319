import React from 'react';
import ScrollIntoView from 'react-scroll-into-view';
import { FaArrowUp } from 'react-icons/fa6';
import { Socials } from '../home/components/Profile';

import './styles/footer.css';

export default function MainFooter() {
  return (
    <footer id='test' className='bg-background-300 flex flex-col px-4 h-20'>
      <div className='container mx-auto'>
        <div className='text-white'>
          <Socials
            gitlabClass='text-[#FC6D27]'
            linkedinClass='text-[#00A0DC]'
          />
        </div>

        <div className='flex items-baseline justify-between'>
          <span className='text-white'>© All rights reserved</span>

          <ScrollIntoView selector='#Home' alignToTop={true}>
            <button
              type='button'
              className='text-background-300 animate-bounce active:scale-[.98] bg-white rounded-full p-2'
            >
              <FaArrowUp />
            </button>
          </ScrollIntoView>
        </div>
      </div>
    </footer>
  );
}
