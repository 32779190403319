import React, { useEffect, useRef, useState } from 'react';
import SubHeading from '../reusable-utilities/SubHeading';
import { BiSolidPaperPlane } from 'react-icons/bi';
import useReturnIntersecting from '../reusable-utilities/hooks/Hooks';

import './styles/contactForm.css';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default function ContactForm({ setActive }) {
  const elemRef = useRef(null);
  const isInView = useReturnIntersecting(elemRef);

  useEffect(() => {
    if (isInView) {
      setActive('Contact');
    }
  }, [isInView, setActive]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = async (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, subject, message } = { ...formData };
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        name,
        email,
        subject,
        message,
      }),
    })
      .then(() =>
        setFormData({ name: '', email: '', subject: '', message: '' })
      )
      .catch((error) => alert(error));
  };

  return (
    <div
      id='Contact'
      className='flex flex-col items-center justify-center mt-[120px]'
    >
      <div className='max-w-[1000px]'>
        <SubHeading title="Let's Chat!" subTitle='' />

        <form
          id='ContactMeForm'
          name='contact'
          data-netlify='true'
          method='POST'
          onSubmit={handleSubmit}
          ref={elemRef}
          className={`${
            isInView
              ? 'animate__animated animate__fadeIn'
              : 'animate__animated animate__fadeOut'
          } contact-form mx-4 mb-4 rounded-lg flex flex-col gap-y-3 w-[700px] bg-background-300 text-white p-4`}
        >
          <input type='hidden' name='form-name' value='contact' />

          <div className='flex justify-between gap-x-2'>
            <input
              name='name'
              type='text'
              placeholder='Name'
              onChange={handleChange}
              className='w-full bg-gray-100 text-background-300 mt-2 p-3 rounded-md focus:ring-action focus:ring-2'
              autoComplete='on'
              value={formData.name}
              required
            />
            <input
              name='email'
              type='email'
              placeholder='Email'
              onChange={handleChange}
              className='w-full bg-gray-100 text-background-300 mt-2 p-3 rounded-md focus:ring-action focus:ring-2'
              autoComplete='on'
              value={formData.email}
              required
            />
          </div>
          <input
            name='subject'
            type='text'
            placeholder='Subject'
            onChange={handleChange}
            className='w-full bg-gray-100 text-background-300 mt-2 p-3 rounded-md focus:ring-action focus:ring-2'
            value={formData.subject}
            required
          />

          <textarea
            name='message'
            id='message'
            cols='50'
            rows='15'
            onChange={handleChange}
            value={formData.message}
            required
            placeholder='  Message'
            className='text-background-300 w-full h-full focus:ring-action rounded-md focus:ring-2'
          ></textarea>
          <button
            type='submit'
            className={`bg-action contact-submit-btn w-full font-semibold  rounded-md h-8 hover:scale-[1.03] active:scale-[.98]`}
          >
            <span className='flex items-center justify-center'>
              Submit
              <BiSolidPaperPlane className='ml-1 h-4 w-4' />
            </span>
          </button>
        </form>
      </div>
    </div>
  );
}
