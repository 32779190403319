import React from 'react';
import './styles/profile.css';

export function WaveDividerTop() {
  return (
    <div className='h-[130px] w-full scale-y-[1.02] overflow-hidden'>
      <div className='divider-parent relative w-full h-full'>
        <img
          className='divider-actual absolute translate-y-[2px] bottom-0 w-full h-[130px]'
          src={require('../../../assets/home/waveTop.png')}
          alt='divider loading issue'
        />
      </div>
    </div>
  );
}

export function WaveDividerBottom() {
  return (
    <div className='h-[130px] bg-background-300 w-full overflow-hidden'>
      <div className='divider-parent relative w-full h-full'>
        <img
          className='divider-actual rotate-180 absolute scale-y-[1.02] bottom-0 w-full h-[130px]'
          src={require('../../../assets/home/waveBottom.png')}
          alt='divider loading issue'
        />
      </div>
    </div>
  );
}
