import { FaLaptopCode } from 'react-icons/fa';
import { SiTailwindcss, SiFastapi, SiDjango, SiRedux } from 'react-icons/si';
import {
  FaCodeBranch,
  FaLink,
  FaHtml5,
  FaCss3Alt,
  FaPython,
  FaDocker,
  FaReact,
} from 'react-icons/fa6';
import { BsPersonWorkspace } from 'react-icons/bs';
import { BiLogoJavascript, BiLogoPostgresql } from 'react-icons/bi';

export const expBullets = [
  {
    id: 1,
    label: 'Professional Experience',
    icon: <BsPersonWorkspace className='text-white h-4 mr-7 inline' />,
  },
  {
    id: 2,
    label: 'Projects',
    icon: <FaCodeBranch className='text-white h-4 mr-7 inline' />,
  },
  {
    id: 3,
    label: 'Programming Skills',
    icon: <FaLaptopCode className='text-white h-4 mr-7 inline' />,
  },
];

export const programmingSkills = [
  { skill: 'HTML', icon: <FaHtml5 />, style: 'bg-[#e34c26] text-white' },
  {
    skill: 'JavaScript',
    icon: <BiLogoJavascript />,
    style: 'bg-[#F0DB4F] text-[#323330]',
  },
  { skill: 'CSS', icon: <FaCss3Alt />, style: 'bg-[#264de4] text-white' },
  { skill: 'TailwindCSS', icon: <SiTailwindcss />, style: 'bg-cyan-300' },
  {
    skill: 'Python',
    icon: <FaPython />,
    style: 'bg-[#FFD43B] text-[#323330]',
  },
  { skill: 'FastAPI', icon: <SiFastapi />, style: 'bg-[#05998b] text-white' },
  { skill: 'Django', icon: <SiDjango />, style: 'bg-[#092e20] text-white' },
  {
    skill: 'PostgreSQL',
    icon: <BiLogoPostgresql />,
    style: 'bg-[#0064a5] text-white',
  },
  { skill: 'Docker', icon: <FaDocker />, style: 'bg-[#0db7ed]' },
  { skill: 'React JS', icon: <FaReact />, style: 'bg-[#61DBFB]' },
  { skill: 'Redux', icon: <SiRedux />, style: 'bg-[#764abc] text-white' },
];

export const projectDetails = [
  {
    title: 'DGNKEEPER',
    time: 2023,
    description:
      '- RPG platform for Game Masters to create and organize campaign lore, while providing players with character management, session tracking, and campaign access',
    subHeading: (
      <a
        href='https://dgnkeeper.com/'
        target='_blank'
        rel='noreferrer noopener'
        className='flex items-center'
      >
        <FaLink className='mr-1' />
        dgnkeeper.com
      </a>
    ),
  },
  {
    title: 'Galkirk',
    time: 2023,
    description: '- Curriculum planning tool used by Hack Reactor Staff',
    subHeading: 'Python | JavaScript | React JS | Redux | HTML | CSS',
  },
];
