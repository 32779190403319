import { useState } from 'react';
import Home from './components/home/Home';
import AboutMe from './components/about/AboutMe';
import Experience from './components/experience/Experience';
import ContactForm from './components/contact/ContactForm';
import MainFooter from './components/footer/Footer';
import { WaveDividerBottom } from './components/home/components/WaveDivider';
import LatestUpdate from './components/latest-update/LatestUpdate';
import './App.css';

function App() {
  const [active, setActive] = useState(null);

  return (
    <div>
      <Home active={active} setActive={setActive} />
      <LatestUpdate setActive={setActive} />
      <AboutMe setActive={setActive} />
      <Experience setActive={setActive} />
      <ContactForm setActive={setActive} />
      <WaveDividerBottom />
      <MainFooter />
    </div>
  );
}

export default App;
