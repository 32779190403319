import React, { useEffect, useRef, useState } from 'react';
import SubHeading from '../reusable-utilities/SubHeading';
import { expBullets, programmingSkills, projectDetails } from './StaticData';
import useReturnIntersecting from '../reusable-utilities/hooks/Hooks';

import './styles/experience.css';

const sectionClass = 'flex flex-col justify-evenly h-[360px] w-full';
const optionClass =
  'flex items-center bg-[#122035] text-background-300 h-[40px] my-[15px] px-2 rounded-md w-[30px] cursor-pointer';

export default function Experience({ setActive }) {
  const [selected, setSelected] = useState(0);
  const [carouselStyle, setCarouselStyle] = useState({});
  const elemRef = useRef(null);
  const isInView = useReturnIntersecting(elemRef);

  useEffect(() => {
    if (isInView) {
      setActive('Experience');
    }
  }, [isInView, setActive]);

  const handleCarousel = (idx) => {
    const offsetHeight = 360;
    const newCarouselOffset = {
      style: { transform: `translateY(${idx * offsetHeight * -1 + 'px'})` },
    };
    setCarouselStyle(newCarouselOffset);
    setSelected(idx);
  };

  const getBullets = () => {
    return expBullets.map((bullet, i) => (
      <button
        type='button'
        key={bullet.id}
        onClick={() => handleCarousel(i)}
        className={
          i === selected ? `${optionClass} selected-option` : optionClass
        }
        style={{ transition: 'width 0.6s ease' }}
      >
        <span>{bullet.icon}</span>
        <span className='option-label'>{bullet.label}</span>
      </button>
    ));
  };

  const getExpSections = () => {
    return (
      <div className='exp-details-carousel' style={carouselStyle.style}>
        {expDetails.map((info) => info)}
      </div>
    );
  };

  return (
    <div
      id='Experience'
      className='flex items-center flex-col justify-center min-h-fit mt-[120px]'
    >
      <div className='exp-content'>
        <SubHeading title='Experience' subTitle='My Development Experience' />
        <div
          ref={elemRef}
          className={`${
            isInView
              ? 'animate__animated animate__fadeIn'
              : 'animate__animated animate__fadeOut'
          } exp-card flex h-[360px] w-[1000px]`}
        >
          <div className='exp-options border-r-2 border-[#122035] h-full w-[320px]'>
            <div className='flex items-center h-full w-full relative'>
              <div className='w-[34px] h-full z-[1] bg-[#122035] absolute rounded-md'></div>

              <div className='bullets w-[80%] relative z-[2]'>
                {getBullets()}
              </div>
            </div>
          </div>

          <div className='exp-bullet-details relative grow w-[600px] overflow-hidden pl-16'>
            {getExpSections()}
          </div>
        </div>
      </div>
    </div>
  );
}

const ExpHeading = ({ heading, subHeading, desc, time }) => {
  return (
    <div className='flex flex-col'>
      <div className='relative flex justify-between items-center'>
        <div className='absolute left-[-30px] top-[8px] h-[15px] w-[15px] bg-secondary rounded-full' />
        <span className='font-bold text-base text-secondary'>{heading}</span>
        <div className='bg-secondary py-1 px-3 text-base rounded-md text-white'>
          {time}
        </div>
      </div>
      <div className='exp-sub-heading text-base'>
        <span>{subHeading}</span>
      </div>
      <div className='exp-desc text-base'>
        <span>{desc}</span>
      </div>
    </div>
  );
};

const expDetails = [
  <div className={sectionClass} key='professionalExperience'>
    <ExpHeading
      heading='Galvanize Inc.'
      subHeading='Fullstack Developer (Remote)'
      desc={
        <ul>
          <li>
            - Performed Frontend & Backend operations on an internal curriculum
            scheduler
          </li>
        </ul>
      }
      time='2023'
    />
    <ExpHeading
      heading='Galvanize Inc.'
      subHeading='Software Engineering Immersive Resident (Remote)'
      desc={
        <ul>
          <li>
            - Mentored 80+ students in Fullstack development through hands-on
            1:1 tutoring and large study lectures
          </li>
        </ul>
      }
      time='2022 - 2023'
    />
  </div>,

  <div className={sectionClass} key='projects'>
    {projectDetails.map((proj) => (
      <ExpHeading
        key={proj.title}
        heading={proj.title}
        subHeading={proj.subHeading}
        desc={proj.description}
        time={proj.time}
      />
    ))}
  </div>,

  <div
    className={`code-skills-container flex flex-wrap gap-2 max-w-[500px] translate-y-[40%] items-center justify-center`}
    key='programmingSkills'
  >
    {programmingSkills.map((skill) => (
      <div
        key={skill.skill}
        className={`${skill.style} flex text-lg items-center gap-x-2 px-3 py-1 rounded-md sm:h-12`}
      >
        <span>{skill.icon}</span>
        <span>{skill.skill}</span>
      </div>
    ))}
  </div>,
];
