import React from 'react';
import landingImg from '../../assets/dgnkeeper-imgs/landingPage.png';

import './styles/latestUpdate.css';

export default function DgnKeeperImgs() {
  return (
    <img
      src={landingImg}
      alt='dgnkeeper showcase'
      className='project-picture h-full w-full rounded-r-lg'
    />
  );
}
