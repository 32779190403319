import React, { useEffect, useRef } from 'react';
import { FaGitlab, FaLinkedin } from 'react-icons/fa6';
import { HiOutlineMail } from 'react-icons/hi';
import { TypeAnimation } from 'react-type-animation';
import ScrollIntoView from 'react-scroll-into-view';
import useReturnIntersecting from '../../reusable-utilities/hooks/Hooks';

import './styles/profile.css';

export default function Profile({ setActive, setRenderSideNav }) {
  const elemRef = useRef(null);
  const isInView = useReturnIntersecting(elemRef);

  useEffect(() => {
    if (isInView) {
      setActive('Home');
      setRenderSideNav(false);
    }

    if (!isInView) {
      setRenderSideNav(true);
    }
  }, [isInView, setActive, setRenderSideNav]);

  const wordItems = [
    'Problem Solver',
    1000,
    'Constant Learner',
    1000,
    'Concert Goer',
    1000,
    'Adventure Seeker',
    1000,
    'Full Stack Enjoyer',
    1000,
  ];

  return (
    <div className='profile-cont flex items-center text-center justify-center h-[10%] min-h-fit'>
      <div className='landing-parent flex items-center text-white'>
        <div className='landing-details'>
          <Socials />

          <NameHeader />

          <DescriptionAni wordItems={wordItems} />

          <CTAButtons />
        </div>
        <div
          ref={elemRef}
          className='profile-picture-container flex items-center justify-center rounded-[50%] h-96 w-96 ml-28'
        >
          <div className='profile-picture-actual h-[92%] w-[92%] rounded-[50%] hover:transition-all hover:scale-[1.07]' />
        </div>
      </div>
    </div>
  );
}

export const Socials = ({ gitlabClass, linkedinClass }) => {
  const socialsClasses = {
    gitlab: gitlabClass ? (
      <span className='ml-2'>Check out my Gitlab</span>
    ) : null,

    linkedin: linkedinClass ? (
      <span className='ml-2'>Contact me on LinkedIn</span>
    ) : null,

    linkClass:
      !gitlabClass && !linkedinClass
        ? 'hover:scale-[1.5] flex items-center text-white'
        : 'flex items-center text-white hover:underline self-start hover:underline-offset-[5px]',
  };

  const socialsContainerClass =
    gitlabClass && linkedinClass
      ? 'flex flex-col'
      : 'flex justify-center gap-6';

  return (
    <div className=''>
      <div className={socialsContainerClass}>
        <a
          href='https://gitlab.com/MalikOmar'
          target='_blank'
          rel='noreferrer'
          className={socialsClasses.linkClass}
        >
          <FaGitlab className={`h-6 w-5 ${gitlabClass}`} />
          {socialsClasses.gitlab}
        </a>
        <a
          href='https://www.linkedin.com/in/malikomarswe/'
          target='_blank'
          rel='noreferrer'
          className={socialsClasses.linkClass}
        >
          <FaLinkedin className={`h-6 w-5 ${linkedinClass}`} />
          {socialsClasses.linkedin}
        </a>
      </div>
    </div>
  );
};

const NameHeader = () => {
  return (
    <div className='landing-details-name text-2xl'>
      <span className='intro-text'>
        Hello, I&apos;m <span className='text-action'>Malik</span>! A Fullstack
        Dev and
      </span>
    </div>
  );
};

const DescriptionAni = ({ wordItems }) => {
  return (
    <div className='landing-details-summary flex flex-col mb-6 mt-2'>
      <span className='intro-text text-white'>
        <h1 className='text-4xl mb-4'>
          <TypeAnimation
            sequence={wordItems}
            repeat={Infinity}
            wrapper='span'
            speed={50}
          />
        </h1>
        <h2 className='tagline mx-auto max-w-[265px]'>
          <span>
            Have an interesting project? Click the button below to get in touch!
          </span>
        </h2>
      </span>
    </div>
  );
};

export const CTAButtons = () => {
  return (
    <div className='cta-btn-container flex justify-center gap-x-7'>
      <ScrollIntoView selector='#Contact' alignToTop={true}>
        <button
          type='button'
          className='cta-btn rounded-md hire-btn flex items-center justify-center text-white bg-action hover:scale-[1.02] active:scale-[.98]'
        >
          <HiOutlineMail className='inline mr-1 h-[20px] w-[20px]' />
          Contact
        </button>
      </ScrollIntoView>
    </div>
  );
};
